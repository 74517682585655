.LeftPanelSubCont1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
  /* min-height: 6.2rem; */
  height: 100%;
  row-gap: 2rem;
}

.sub1 {
  /* background-color: rgba(255, 255, 255, 0.4); */
  /* backdrop-filter: blur(5px); */
  background-color: rgba(125, 143, 157, 0.45);
  /* -webkit-backdrop-filter: blur(18px); */
  /* filter: blur(5px); */
  /* background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(18px);
  /* border: 1px solid rgba(255, 255, 255, 0.274); */
  /* min-width: 40%; */
  width: 66%;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.sub2 {
  background-color: rgba(125, 143, 157, 0.45);
  /* min-width: 100px; */
  width: 15%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* row-gap: 1.2rem; */
  /* min-height: 100%;*/
  height: 100%;
  padding: 5px;
}

.sub3 {
  background-color: rgba(125, 143, 157, 0.45);

  /* min-width: 100px; */
  width: 15%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* row-gap: 1.2rem; */
  /* min-height: 100%; */
  height: 100%;
  padding: 5px;
}

.sub2 span {
  color: white;
}

.sub3 span {
  color: white;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.flexRow1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

@media only screen and (max-width: 928px) {
  .flexRow span:nth-child(1) {
    font-size: 16px !important;
  }

  .flexRow span:nth-child(2) {
    font-size: 24px !important;
  }
}

@media only screen and (max-width: 862px) {
  .sub1 {
    /* background-color: rgba(255, 255, 255, 0.4); */
    /* backdrop-filter: blur(5px); */
    background-color: rgba(125, 143, 157, 0.5);
    /* background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px); */
    /* background-color: red; */
    /* border: 1px solid rgba(255, 255, 255, 0.274); */
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 46%;
  }

  .sub2 {
    background-color: rgba(125, 143, 157, 0.5);
    /* min-width: 100px; */
    width: 48%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    height: 46%;
  }

  .sub3 {
    background-color: rgba(125, 143, 157, 0.5);
    width: 48%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    height: 46%;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .flexRow span:nth-child(1) {
    font-size: 14px !important;
    word-spacing: 3px !important;
  }

  .flexRow span:nth-child(2) {
    font-size: 22px !important;
  }

  .flexRow1 span {
    font-size: 20px !important;
  }
}

/* @media only screen and (max-width: 550px) {
  .LeftPanelSubCont1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;
    min-height: 6.2rem;
    height: 100%;
    row-gap: 22px;
    column-gap: 15px;
  }
  .sub1 {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.274);
  
    width: 100%;
    border-radius: 8px;
    gap: 1rem;
  }
  .sub2 {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.274);
    width: 46%;
    border-radius: 8px;
    padding: 5px;
  }

  .sub3 {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.274);
    width: 46%;
    border-radius: 8px;
    padding: 5px;
  }
} */

@media only screen and (max-width: 374px) {
  .sub1 {
    /* background-color: rgba(255, 255, 255, 0.4); */
    /* backdrop-filter: blur(5px); */
    background-color: rgba(125, 143, 157, 0.5);
    /* background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px); */
    /* background-color: red; */
    /* border: 1px solid rgba(255, 255, 255, 0.274); */
    /* min-width: 40%; */
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 28%;
  }

  .sub2 {
    /* background-color: red; */
    background-color: rgba(125, 143, 157, 0.5);
    /* min-width: 100px; */
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    height: 28%;
  }

  .sub3 {
    background-color: rgba(125, 143, 157, 0.5);
    width: 100%;

    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 28%;
    padding: 5px;
  }
}

@media only screen and (max-width: 391px) {
  .flexRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.4rem;
  }
  .flexRow span {
    white-space: pre-wrap !important;
    word-spacing: 1px !important;
  }
}

@media only screen and (max-width: 323px) {
  .flexRow1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .flexRow1 span {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 266px) {
  .flexRow1 img {
    width: 30px;
    height: 30px;
  }
}
