.headerCont {
  /* background-color: #e4e4e6; */
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 5px;
  padding-top: 15px;
}
.headerCont a {
  font-size: 1.3rem;
  /* padding-left: 5em; */
  color: white;
  cursor: pointer;
}
.headerCont a:hover {
  color: white;
}
.dateCont {
  margin-left: auto;
  /* padding: 30px; */
  display: flex;
  flex-direction: row;
  color: #dfdfdf;
  margin-right: 5px;
}
