@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
  /* font-size: 62.5%; */
}

html,
body {
  height: 100%;
}

/* Set core body defaults */
body {
  font-family: Inter, sans-serif;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background: #070a1b;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.react-calendar {
  border: 0px !important;
  width: auto !important;
  /* background: #282744 !important; */
  background: transparent !important;
}

.react-calendar__navigation button {
  min-width: 25px !important;
  background: none !important;
  color: white !important;
}

.react-calendar__month-view__weekdays {
  color: white !important;
}

.react-calendar__month-view__days__day {
  color: white !important;
}
.react-calendar__month-view__days__day:hover {
  color: black !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #878787 !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #ff5a2d !important;
}
.react-calendar__tile--now {
  background: #abab5a !important;
}

.react-calendar__tile--now:hover {
  background: #abab5a !important;
  color: white !important;
}

.react-calendar__tile--active {
  background: #006edc !important;
}

.react-calendar__tile--active:hover {
  background: #006edc !important;
  color: white !important;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
}

.react-calendar__year-view .react-calendar__tile {
  color: white !important;
}

.react-calendar__year-view .react-calendar__tile:hover {
  color: black !important;
}

.react-calendar__decade-view .react-calendar__tile {
  color: white !important;
}

.react-calendar__decade-view .react-calendar__tile:hover {
  color: black !important;
}

.react-calendar__century-view .react-calendar__tile {
  color: white !important;
}

.react-calendar__century-view .react-calendar__tile:hover {
  color: black !important;
}

.logo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  /* background-color: black; */
  background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("https://cdn.pixabay.com/photo/2015/11/19/07/27/star-1050487_1280.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: slow-display-animation 3s ease-in-out forwards;
  animation-delay: 2s;
  z-index: 1;
}

/* Define the animation keyframes */
@keyframes logoAnimation {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.globe {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  height: 60vh;
  animation: GlobeAnimation 3s forwards;
  animation-delay: 2s;
  z-index: 2;
}

/* Define the animation keyframes */
@keyframes GlobeAnimation {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.fade-out {
  width: 100%;
  height: 100vh;
  background-color: red;

  animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
    background-color: black;
  }
  100% {
    opacity: 0;
    background-color: transparent;
    width: 0;
    height: 0;
  }
}

.slowDisplay span {
  opacity: 0;
  animation: slow-display-animation 3s ease-in-out forwards;
}

@keyframes slow-display-animation {
  from {
    opacity: 1;
    background-color: black;
  }
  to {
    opacity: 0;
  }
}
