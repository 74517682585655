.rightPanelSubCont2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* flex-grow: 1; */
  width: 100%;

  /* min-height: 6.2rem; */
  /* max-height: fit-content; */
  /* height: 11.5rem; */
  align-items: center;
  height: 100%;
  justify-content: space-between;
  /* gap: 1rem; */

  /* display: none; */
}

.sub1 {
  background-color: rgba(125, 143, 157, 0.45);
  width: 100%;
  /* flex-grow: 1; */
  border-radius: 8px;
  min-height: 3rem;
  height: 28.5%;
  padding: 5px;
  /* flex-grow: 1; */
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}

.sub3 {
  background-color: rgba(125, 143, 157, 0.45);
  width: 100%;
  /* flex-grow: 1; */
  border-radius: 8px;
  min-height: 3rem;
  height: 28.5%;
  padding: 5px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.sub3Sub {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: start;
  height: 73%;
}

.flexRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 100%;
  row-gap: 4px;
  /* justify-content: space-between; */
}

.flexRow span:nth-child(1) {
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.flexRow span:nth-child(2) {
  color: white;
  font-size: 12px;
  /* white-space: nowrap; */
}

.flexRow3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 3px;
}

.flexRow3 span:nth-child(1) {
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.flexRow3 span:nth-child(2) {
  color: white;
  font-size: 12px;
  /* white-space: nowrap; */
}

.roundCont {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.roundSubCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 2.3rem;
  gap: 6px;
}

.roundSubCont span {
  color: white;
  font-size: 12px;
}

.round {
  height: 72%;
  width: 50%;
  background-color: #535db9;
  border-radius: 50%;
}

.round1 {
  height: 72%;
  width: 30%;
  background-color: #f23849;
  border-radius: 50%;
}

@media only screen and (max-width: 1600px) {
  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    /* flex-grow: 1; */
    border-radius: 8px;
    min-height: 3rem;
    height: 28.5%;
    padding: 5px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
  }
  .flexRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 100%;
    row-gap: 4px;
    /* justify-content: space-between; */
  }

  .flexRow span:nth-child(1) {
    color: white;
    font-size: 20px;
    font-weight: 600;
  }

  .flexRow span:nth-child(2) {
    color: white;
    font-size: 10px;
    /* white-space: nowrap; */
  }

  .flexRow3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 0px;
  }

  .flexRow3 span:nth-child(1) {
    color: white;
    font-size: 20px;
    font-weight: 600;
  }

  .flexRow3 span:nth-child(2) {
    color: white;
    font-size: 10px;
    /* white-space: nowrap; */
  }
}

@media only screen and (max-width: 1004px) {
  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    /* flex-grow: 1; */
    border-radius: 8px;
    min-height: 3rem;
    height: 28.5%;
    padding: 5px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
  }
  .flexRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 100%;
    row-gap: 4px;
    /* justify-content: space-between; */
  }

  .flexRow span:nth-child(1) {
    color: white;
    font-size: 26px;
    font-weight: 600;
  }

  .flexRow span:nth-child(2) {
    color: white;
    font-size: 12px;
    /* white-space: nowrap; */
  }

  .flexRow3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 0px;
  }

  .flexRow3 span:nth-child(1) {
    color: white;
    font-size: 26px;
    font-weight: 600;
  }

  .flexRow3 span:nth-child(2) {
    color: white;
    font-size: 12px;
    /* white-space: nowrap; */
  }
}

@media only screen and (max-width: 1193px) {
  .rightPanelSubCont2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* flex-grow: 1; */
    width: 100%;

    align-items: center;
    height: 100%;
    justify-content: space-between;
    gap: 1rem;

    /* display: none; */
  }
  .sub1 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    min-height: 2rem;
    height: 28.5%;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
  }

  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    min-height: 3rem;
    height: 28.5%;
    padding-left: 10px;
    /* padding: 1rem;
    padding-top: 5px;
    padding-bottom: 5px; */
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 880px) {
  .sub1 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 3rem;
    padding: 5px;
    /* flex-grow: 1; */
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
  }

  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 3rem;
    padding: 5px;
    padding-left: 10px;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 3px;
  }
}

@media only screen and (max-width: 497px) {
  .sub1 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 3rem;
    padding: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
  }

  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 3rem;
    padding: 1rem;
    padding-left: 10px;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .rightPanelSubCont2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;

    /* min-height: 6.2rem; */
    /* max-height: fit-content; */
    /* height: 11.5rem; */
    align-items: center;
    gap: 22px;
  }
}

@media only screen and (max-width: 796px) {
  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 3rem;
    padding: 5px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 3px;
  }
}

@media only screen and (max-width: 588px) {
  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 4rem;
    padding: 5px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 3px;
  }
}

@media only screen and (max-width: 538px) {
  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 3.5rem;
    padding: 5px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 12px;
  }
}

@media only screen and (max-width: 498px) {
  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 3rem;
    padding: 1rem;
    padding-left: 10px;
    /* padding-top: 3px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .sub1 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 5.5rem;
    padding: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
  }

  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 5.5rem;
    padding: 1rem;
    padding-top: 5px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 14px;
  }
}

@media only screen and (max-width: 302px) {
  .sub1 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 6.5rem;
    padding: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
  }

  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 6.5rem;
    padding: 1rem;
    padding-top: 5px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 6px;
  }
}

@media only screen and (max-width: 278px) {
  .sub1 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 6.5rem;
    padding: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
  }

  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 6.5rem;
    padding: 5px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 6px;
  }
}

@media only screen and (max-width: 239px) {
  .flexRow span:nth-child(1) {
    color: white;
    font-size: 22px;
    font-weight: 600;
  }

  .flexRow span:nth-child(2) {
    color: white;
    font-size: 12px;
  }
}

@media only screen and (max-width: 225px) {
  .sub1 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 8rem;
    padding: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
  }
  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 11rem;
    padding-left: 10px;
    display: flex;
  }

  .sub3Sub {
    display: flex;

    align-items: center;
    gap: 1rem;
    width: 100%;
  }
}

@media only screen and (max-width: 191px) {
  .sub1 {
    background-color: rgba(125, 143, 157, 0.45);
    /* min-width: 8rem; */
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 11rem;
    padding: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 1rem;
  }

  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    /* min-width: 8rem; */
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 11rem;
    padding: 1rem;
    padding-left: 10px;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 4px;
  }

  .roundCont {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
  }
}

@media only screen and (max-width: 191px) {
  .sub3 {
    background-color: rgba(125, 143, 157, 0.45);
    /* min-width: 8rem; */
    width: 100%;
    flex-grow: 1;
    border-radius: 8px;
    height: 12rem;
    padding: 1rem;
    padding-left: 10px;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.2rem;
    gap: 4px;
  }
}
