.LeftPanelSubCont2 {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  /*flex-grow: 1; */
  width: 100%;
  /* column-gap: 15px;
  row-gap: 22px;
  justify-content: space-between;
  align-items: center; */
  column-gap: 1.5rem;
  height: 100%;
}

.sub0 {
  min-width: 5rem;
  flex-grow: 1;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-items: center;
}

.sub1 {
  background-color: rgba(125, 143, 157, 0.5);
  width: 100%;
  flex-grow: 1;
  border-radius: 8px;
  height: 88%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  transition: transform 0.3s;
}

.sub1:hover {
  transform: scale(1.1);
  margin-bottom: 5px;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.4);
}

.sub0 .sub1 span {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.sub0 span {
  color: white;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.hoverableDiv {
  position: relative;
  display: inline-block;
  margin: 10px;
}

@media only screen and (max-width: 1600px) {
  .sub0 .sub1 span {
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
  }

  .sub0 span {
    color: white;
    font-size: 13px;
  }
}

/* @media only screen and (max-width: 2000px) {
  .sub0 .sub1 span {
    font-size: 14px;
    font-weight: 600;
    color: white;
  }

  .sub0 span {
    color: white;
    font-size: 14px;
  }
} */

@media only screen and (max-width: 1004px) {
  .LeftPanelSubCont2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 1.5rem;
    height: 100%;
    row-gap: 1.5rem;
  }

  .sub0 {
    width: 8rem;
    /* flex-grow: 1; */
    border-radius: 8px;
    height: 13rem;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-items: center;
  }
}
