.rightPanelCont {
  /* background-color: greenyellow; */
  flex-grow: 1;
  min-width: 12.3rem;
  width: 12.3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4.5%;
  /* justify-content: space-between; */
  /* display: none; */
}

.rightPanelContSub1 {
  /* background-color: orange; */
  padding: 2px;
  min-height: 13rem;
  height: 45%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightPanelContSub2 {
  /* background-color: rgb(34, 0, 255); */
  padding: 2px;
  min-height: 15.2rem;
  height: 50.5%;
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: center;
  flex-wrap: wrap;
  /* overflow: scroll; */
  /* display: none; */
}

@media only screen and (max-width: 1004px) {
  .rightPanelCont {
    /* background-color: greenyellow; */
    flex-grow: 1;
    min-width: 12.3rem;
    width: 100%;
    height: 20rem;
    display: flex;
    flex-direction: row;
    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: space-between;
  }

  .rightPanelContSub1 {
    /* background-color: orange; */
    padding: 2px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rightPanelContSub2 {
    /* background-color: rgb(34, 0, 255); */
    padding: 2px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 880px) {
  .rightPanelContSub1 {
    /* background-color: orange; */
    padding: 2px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rightPanelContSub2 {
    /* background-color: rgb(34, 0, 255); */
    padding: 2px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 796px) {
  .rightPanelContSub1 {
    /* background-color: orange; */
    padding: 2px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rightPanelContSub2 {
    /* background-color: rgb(34, 0, 255); */
    padding: 2px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 538px) {
  .rightPanelCont {
    /* background-color: greenyellow; */
    flex-grow: 1;
    min-width: 12.3rem;
    width: 100%;
    height: 22rem;
    display: flex;
    flex-direction: row;
    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 500px) {
  .rightPanelCont {
    /* background-color: greenyellow; */
    flex-grow: 1;
    min-width: 12.3rem;
    width: 100%;
    height: 28rem;
    display: flex;
    flex-direction: row;
    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 480px) {
  .rightPanelCont {
    /* background-color: greenyellow; */
    flex-grow: 1;
    min-width: 8.3rem;
    /* width: 12.3rem; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    column-gap: 2rem;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 224px) {
  .rightPanelCont {
    /* background-color: greenyellow; */
    flex-grow: 1;
    min-width: 8.3rem;
    /* width: 12.3rem; */
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 180px) {
  .rightPanelCont {
    /* background-color: greenyellow; */
    flex-grow: 1;
    min-width: 5.3rem;
    /* width: 12.3rem; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 130px) {
  .rightPanelCont {
    /* background-color: greenyellow; */
    flex-grow: 1;
    min-width: 3.3rem;
    /* width: 12.3rem; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: space-between;
  }
}
