.rightPanelSubCont1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;

  /* min-height: 6.2rem; */
  /* max-height: fit-content; */
  height: 100%;
  align-items: center;
  overflow: auto;
}

.sub1 {
  background-color: rgba(125, 143, 157, 0.45);
  min-width: 100%;
  /* width: 8rem; */
  flex-grow: 1;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: baseline;
  overflow-y: auto;
  /* flex-grow: 1; */
}

@media only screen and (max-width: 470px) {
  .rightPanelSubCont1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;

    /* min-height: 6.2rem; */
    /* max-height: fit-content; */
    /* height: 14rem; */
    align-items: center;
  }
}
