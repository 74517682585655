.mainContainer {
  background-color: #333357;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: auto 2fr 8fr;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: -1000;
  opacity: 1;
  /* animation: slow-display-animation1 6s ease-in-out forwards; */
}

.mainContainer1 {
  background-color: #333357;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: auto 2fr 8fr;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mainContainer2 {
  background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 250%
    ),
    url("./images/landingBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(90px);
  filter: brightness(80%);
  /* background-color: black; */
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  z-index: -1000000000000000;
  opacity: 1;
  /* animation: slow-display-animation1 6s ease-in-out forwards; */
}

.mainContainer2Sub {
  width: 100%;
  height: 100%;
  /* background-color: aqua; */
  padding: 1.5rem;
  padding-top: 0.8rem;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  overflow: auto;
}

/* ::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar {
  width: 2px;
  height: 7px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2.5px;
}

.slowDisplay1 span {
  opacity: 0;
  animation: slow-display-animation1 3s ease-in-out forwards;
}

@keyframes slow-display-animation1 {
  from {
    opacity: 0;
    background-color: black;
  }
  to {
    opacity: 1;
  }
}

.logo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  background-color: black;
  /* background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("https://cdn.pixabay.com/photo/2015/11/19/07/27/star-1050487_1280.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: slow-display-animation 5s ease-in-out forwards;
  /* animation-delay: 2s; */
  z-index: 0;
}

/* Define the animation keyframes */
@keyframes logoAnimation {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.globe {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 270px;
  animation: GlobeAnimation 2s forwards;
  animation-delay: 1s;
  z-index: 2;
}

@keyframes GlobeAnimation {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.fade-out {
  width: 100%;
  height: 100vh;
  background-color: red;

  animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
    background-color: black;
  }
  100% {
    opacity: 0;
    background-color: transparent;
    width: 0;
    height: 0;
  }
}

.slowDisplay span {
  opacity: 0;
  animation: slow-display-animation 3s ease-in-out forwards;
}

@keyframes slow-display-animation {
  from {
    opacity: 1;
    background-color: black;
    z-index: 1;
  }
  to {
    opacity: 0;
    z-index: 0;
  }
}

@media (max-width: 1365px) {
  .mainContainer {
    background-color: #333357;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: auto 2fr 8fr;
    overflow-y: scroll;
    overflow-x: scroll;
    row-gap: 1rem;
  }
}

@media only screen and (max-width: 1004px) {
  /* .mainContainer2Sub {
    width: 100%;
    height: 100%;
    background-color: aqua;
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    row-gap: 2.5rem;
    flex-wrap: wrap;
    overflow: scroll;
  } */
}

@media only screen and (max-width: 796px) {
  .mainContainer2 {
    /* background-image: -webkit-linear-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      ),
      url("https://img.freepik.com/free-vector/gradient-network-connection-background_23-2148874050.jpg"); */

    background-image: url("https://png.pngtree.com/thumb_back/fh260/back_our/20190622/ourmid/pngtree-network-information-electronic-technology-background-image_209089.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(90px);
    filter: brightness(80%);
    /* background-color: black; */
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;

    z-index: -1000000000000000;
    opacity: 1;
    /* animation: slow-display-animation1 6s ease-in-out forwards; */
  }

  /* .mainContainer2Sub {
    width: 100%;
    height: 100%;
    background-color: aqua;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    flex-wrap: wrap;
    overflow: scroll;
  } */
}

@media (max-width: 1172px) {
  .globe {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 230px;
    height: 235px;
    animation: GlobeAnimation 3s forwards;
    animation-delay: 2s;
    z-index: 2;
  }

  @keyframes GlobeAnimation {
    0% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    100% {
      top: 0;
      left: 0;
      transform: translate(0, 0);
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
}

@media (max-width: 500px) {
  .globe {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 205px;
    animation: GlobeAnimation 3s forwards;
    animation-delay: 2s;
    z-index: 2;
  }

  @keyframes GlobeAnimation {
    0% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    100% {
      top: 0;
      left: 0;
      transform: translate(0, 0);
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
}

@media (max-width: 220px) {
  .globe {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 180px;
    height: 185px;
    animation: GlobeAnimation 3s forwards;
    animation-delay: 2s;
    z-index: 2;
  }

  @keyframes GlobeAnimation {
    0% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    100% {
      top: 0;
      left: 0;
      transform: translate(0, 0);
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
}

@media (max-width: 180px) {
  .globe {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 160px;
    height: 165px;
    animation: GlobeAnimation 3s forwards;
    animation-delay: 2s;
    z-index: 2;
  }

  @keyframes GlobeAnimation {
    0% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    100% {
      top: 0;
      left: 0;
      transform: translate(0, 0);
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
}
