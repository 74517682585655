.overviewCont {
  /* background-color: yellow; */
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-bottom: -28px;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
}

.overviewContDiv {
  flex-basis: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem, 0;
  /* padding-top: 2rem; */
}

.overviewSubCont {
  background-color: #28427b;
  height: 100%;
  width: 20rem;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 1.5fr 2fr;
  /* max-width: 50rem; */
  /* min-width: 20rem; */
  overflow: hidden;
}

.subContTop {
  display: grid;
  grid-template-columns: 0.8fr 3fr 1fr;
  align-items: center;
  /* background-color: white; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.subContWave {
  /* background-color: orange; */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.areaChart {
  width: 502;
}

.roundedDiv {
  margin-left: 5px;
  height: 35px;
  width: 35px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.img {
  width: 25px;
  height: 25px;
  margin: auto;
  margin-top: 4px;
}

@media (max-width: 1317px) {
  .overviewCont {
    /* background-color: yellow; */
    display: grid;
    height: 100%;
    gap: 2rem;
    column-gap: 0;
    grid-template-columns: 1fr 1fr;
  }

  .overviewSubCont {
    background-color: #28427b;
    height: 100%;
    width: 90%;
    border-radius: 8px;
    display: grid;
    grid-template-rows: 1.5fr 2fr;

    overflow: hidden;
  }
}

/* @media (max-width: 1362px) {
  .overviewCont {
    background-color: yellow;
    padding: 1rem;
    display: grid;
    height: 100%;
    gap: 2rem;
    column-gap: 0;
    grid-template-columns: 1fr 1fr;
  }

  .overviewSubCont {
    background-color: #28427b;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    display: grid;
    grid-template-rows: 1.5fr 2fr;

    overflow: hidden;
  }

  .overviewContDiv {
    flex-basis: 25%;
    display: flex;

    align-items: center;
    padding: 0.5rem, 0;
    padding-right: 0.6rem;
  }
} */

@media (max-width: 560px) {
  .overviewCont {
    /* background-color: yellow; */
    padding: 1rem;
    display: grid;
    height: 75%;
    gap: 2rem;
    column-gap: 0;
    grid-template-columns: 1fr;
    /* padding: 0.5rem;
    padding-right: 6rem; */
  }

  .overviewSubCont {
    background-color: #28427b;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    display: grid;
    grid-template-rows: 1.5fr 2fr;

    overflow: hidden;
  }

  .overviewContDiv {
    flex-basis: 25%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 0.5rem, 0;
    padding-right: 0.6rem;
    /* padding-top: 2rem; */
  }
}
