.leftSubPanelCont3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;

  /* min-height: 6.2rem; */
  /* max-height: fit-content; */
  height: 100%;
  align-items: center;
}

.sub1 {
  background-color: rgba(35, 36, 57);
  backdrop-filter: blur(5px);
  min-width: 100%;
  /* width: 8rem; */
  flex-grow: 1;
  padding: 1rem;
  padding-right: 2.5rem;
  padding-top: 2rem;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  /* flex-grow: 1; */
}

.customTooltip {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.customTooltip .label {
  margin: 0;
}

.customTooltip .avgValue {
  margin: 0;
  font-weight: bold;
}

@media only screen and (max-width: 546px) {
  .sub1 {
    background-color: rgba(35, 36, 57);
    backdrop-filter: blur(5px);
    min-width: 100%;
    /* width: 8rem; */
    flex-grow: 1;
    padding: 1rem;
    padding-left: 0.5rem;
    padding-right: 2rem;
    padding-top: 2rem;
    border-radius: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    /* flex-grow: 1; */
  }
}

@media only screen and (max-width: 497px) {
  .sub1 {
    background-color: rgba(35, 36, 57);
    backdrop-filter: blur(5px);
    min-width: 100%;
    /* width: 8rem; */
    flex-grow: 1;
    padding: 1rem;
    padding-left: unset;
    padding-right: 1rem;
    padding-top: 2rem;
    border-radius: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    /* flex-grow: 1; */
  }
}

@media only screen and (max-width: 472px) {
  .sub1 {
    background-color: rgba(35, 36, 57);
    backdrop-filter: blur(5px);
    min-width: 100%;
    /* width: 8rem; */
    flex-grow: 1;
    padding: 1rem;
    padding-left: unset;
    padding-right: 1rem;
    padding-top: 2rem;
    border-radius: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    /* flex-grow: 1; */
  }
}
