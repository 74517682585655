.leftPanelCont {
  /* background-color: yellow; */
  flex-grow: 1;
  min-width: 70%;
  width: 71%;
  display: flex;
  flex-direction: column;
  gap: 4%;
  height: 100%;
  /* justify-content: space-between; */
}

.leftPanelContSub1 {
  /* background-color: orange; */
  padding: 2px;
  min-height: 4rem;
  height: 15%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.leftPanelContSub2 {
  /* background-color: violet; */
  padding: 2px;
  min-height: 8rem;
  height: 27.5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: none; */
}

.leftPanelContSub3 {
  /* background-color: gray; */
  /* padding: 2px; */
  min-height: 15.3rem;
  height: 52%;
  width: 100%;
  /* display: none; */
}

@media only screen and (max-width: 1004px) {
  .leftPanelCont {
    /* background-color: yellow; */
    flex-grow: 1;
    min-width: 70%;
    width: 71%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: fit-content;
    /* justify-content: space-between; */
  }
  .leftPanelContSub1 {
    /* background-color: orange; */
    padding: 2px;
    /* min-height: 4rem; */
    height: 10rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .leftPanelContSub2 {
    /* background-color: violet; */
    padding: 2px;
    min-height: 8rem;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* display: none; */
  }

  .leftPanelContSub3 {
    /* background-color: gray; */
    /* padding: 2px; */
    min-height: 15.3rem;
    height: 30rem;
    width: 100%;
    /* display: none; */
  }
}

@media only screen and (max-width: 862px) {
  .leftPanelContSub1 {
    /* background-color: orange; */
    padding: 2px;
    /* min-height: 4rem; */
    height: 20rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

@media only screen and (max-width: 374px) {
  .leftPanelContSub1 {
    /* background-color: orange; */
    padding: 2px;
    /* min-height: 4rem; */
    height: 32rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

/* @media only screen and (max-width: 1004px) {
  .leftPanelCont {
    background-color: yellow;
    flex-grow: 1;
    min-width: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 22px;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 796px) {
  .leftPanelCont {
    background-color: yellow;
    flex-grow: 1;
    min-width: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 22px;
    height: 100%;
    justify-content: space-between;
  }

  .leftPanelContSub2 {
    background-color: violet;
    padding: 2px;
    max-height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 550px) {
  .leftPanelContSub1 {
    background-color: orange;
    padding: 2px;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 247px) {
  .leftPanelContSub1 {
    background-color: orange;
    padding: 2px;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
} */
