.headerCont {
  /* background-color: #e4e4e6; */
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 5px;
  padding-top: 15px;
}
.headerCont a {
  font-size: 1.3rem;
  /* padding-left: 5em; */
  color: white;
  cursor: pointer;
}
.headerCont a:hover {
  color: white;
}

.headerCont img {
  width: 10rem;
}

.headerCont a img {
  width: 2rem;
}
.dateCont {
  margin-left: auto;
  /* padding: 30px; */
  display: flex;
  flex-direction: row;
  color: #dfdfdf;
  margin-right: 1.5rem;
}

.logoutPopup {
  position: absolute;
  left: 2%;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 8rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 10rem;
  border-radius: 5px;
  cursor: pointer;
}

.logoutPopup p {
  margin: 0;
  padding: 5px;
  cursor: pointer;
  color: rgb(40, 100, 179);
  font-size: 16px;
}

.logoutPopup p:hover {
  color: red;
}

@media only screen and (max-width: 462px) {
  .dateCont {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    color: #dfdfdf;
    margin-right: 1rem;
    gap: 5px;
  }

  .headerCont img {
    width: 10rem;
  }

  .headerCont a img {
    width: 2rem;
  }
}
