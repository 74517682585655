.analyticsCont {
  display: grid;
  grid-template-columns: 2fr 3.5fr 2fr;
  /* flex-direction: row;
  flex-wrap: wrap; */
  width: 100%;
  /* margin-left: 4rem; */
}

.circleandGraph {
  /* background-color: red; */
  display: grid;
  grid-template-rows: 1fr 1fr;
  /* padding-left: 16%; */
}

.circleCont {
  /* background-color: white; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.circleInnerCont {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 1rem;
}

.circleDiv {
  /* background-color: cyan; */
  justify-self: start;
  /* margin-left: 4.5rem; */
  padding-left: 3rem;
}

.circleWidth {
  width: 6rem;
}

.lineChartdiv {
  /* background-color: #2a2b49; */
  width: fit-content;
}

.lineChartCont {
  /* background-color: red; */
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  height: fit-content;
  flex-direction: column;
}

.lineChartP {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineChartP span {
  margin-top: 12px;
}

.radialandCalender {
  /* background-color: violet; */
  display: grid;
  grid-template-rows: 1fr 3fr;
}

.radialCont {
  /* background-color: #9b9b9b; */
  display: grid;
  width: 22rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  align-self: center;
  justify-self: center;
  /* padding: 0.2rem; */
}

.radialContFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-left: 2rem; */
}

.current-month {
  background-color: red !important;
}

.calendarCont {
  /* background-color: purple; */
  width: min-content !important;
  align-items: center;
  align-self: start;
  justify-self: center;
  padding: 0.2rem;
  padding-top: 0.7rem;
  padding-right: 0.5rem;
  color: rgb(153, 153, 153);
}
.weeklyCont {
  display: flex;
  background-color: #282744;
  padding: 0.6rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  justify-content: space-between;
}

.weeklyFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3rem !important;
}

.weeklyFlex span {
  display: none;
}

.weeklyP {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  margin-bottom: 10px;
}

.donutandSpider {
  /* background-color: cyan; */
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.donutCont {
  width: 102%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  align-items: center;
  /* background-color: orangered; */
}

.donutLegend {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 0.2rem;
}

.dlWidth {
  justify-self: end;
}

.donutLegendDiv {
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.roundedDiv {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-top: 4px;
}

.spiderCont {
  /* background-color: yellow; */
  display: grid;
  align-items: center;
  justify-content: center;
}

.spiderCont > div svg {
  overflow: visible;
}

@media (max-width: 1365px) {
  .analyticsCont {
    display: flex;
    /* grid-template-columns: 2fr 3.5fr 2fr; */
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
  }
  .donutandSpider {
    display: grid;
    grid-template-rows: 1fr 1fr;
    flex-basis: 40%;
    gap: 2rem;
  }

  .circleandGraph {
    display: grid;
    grid-template-rows: 1fr 1fr;
    flex-basis: 60%;
    gap: 2rem;
  }

  .circleCont {
    /* background-color: white; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: fit-content;
    justify-self: start;
    column-gap: 8rem;
    margin-left: 5rem;
  }

  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: fit-content;
    justify-self: start;
    margin-left: 5rem;
  }

  /* .donutCont {
    width: 110%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }  */
}

@media (max-width: 1116px) {
  /* .radialandCalender {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    margin-top: 0 !important;
  } */
  .analyticsCont {
    display: flex;
    /* grid-template-columns: 2fr 3.5fr 2fr; */
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    margin-top: 5rem;
  }
  .donutandSpider {
    display: flex;
    height: 30%;
    flex-basis: 100%;
    gap: 2rem;
  }
  .donutCont {
    flex-basis: 50%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
    /* background-color: orangered; */
  }

  .spiderCont {
    /* background-color: yellow; */
    display: grid;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
  }

  .circleWidth {
    width: 10rem;
  }

  .circleandGraph {
    /* background-color: red; */
    display: grid;
    grid-template-rows: 1fr 1fr;
    /* padding-left: 16%; */
    flex-basis: 100%;
    height: fit-content;
    margin-top: 5rem;
  }

  .circleCont {
    /* background-color: white; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 85%;
    justify-content: center;
    margin: 0px;
    justify-self: center;
  }
}

@media (max-width: 1116px) {
  /* .radialandCalender {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  } */
  .donutandSpider {
    display: flex;
    height: 30%;
    flex-basis: 100%;
    gap: 2rem;
  }
  .donutCont {
    flex-basis: 50%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
    /* background-color: orangered; */
  }

  .spiderCont {
    /* background-color: yellow; */
    display: grid;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
  }
}

@media (max-width: 993px) {
  .circleCont {
    /* background-color: white; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-left: 3rem;
  }
}

@media (max-width: 925px) {
  .circleCont {
    /* background-color: white; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-left: 2rem;
  }
}

@media (max-width: 875px) {
  .circleCont {
    /* background-color: white; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-left: 1.5rem;
  }
}

@media (max-width: 868px) {
  .circleCont {
    /* background-color: white; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-left: 1rem;
  }
}

@media (max-width: 1076px) {
  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    margin: 0px;
    justify-self: center;
  }
}

@media (max-width: 1047px) {
  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 93%;
    justify-content: center;
    margin: 0px;
    justify-self: center;
  }
}
@media (max-width: 1017px) {
  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 97%;
    justify-content: center;
    margin: 0px;
    justify-self: center;
  }
}

@media (max-width: 989px) {
  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: 0px;
    justify-self: center;
  }
}

@media (max-width: 968px) {
  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    margin: 0px;
    justify-self: center;
  }
}

@media (max-width: 915px) {
  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 97%;
    justify-content: center;
    margin: 0px;
    justify-self: center;
  }
}

@media (max-width: 882px) {
  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: 0px;
    justify-self: center;
  }
}

@media (max-width: 871px) {
  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 95%;
    justify-content: center;
    margin: 0px;
    justify-self: center;
  }
}

@media (max-width: 1021px) {
  .donutandSpider {
    display: flex;
    height: 30%;
    flex-basis: 100%;
    gap: 2rem;
  }
}

@media (max-width: 1115px) {
  .radialandCalender {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 1364px) {
  /* .radialandCalender {
    display: flex;
    flex-direction: row;
   
  } */

  .radialandCalender {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 4rem;
  }

  .radialCont {
    /* background-color: #9b9b9b; */
    display: grid;
    /* width: 22rem; */
    grid-template-columns: 1fr 1fr;
    align-items: center;
    align-self: center;
    justify-self: center;
    flex-basis: 47.5%;
    height: 100%;
  }

  .calendarCont {
    /* background-color: purple; */
    /* width: 22rem; */
    align-items: center;
    align-self: start;
    justify-self: center;
    padding: 0.2rem;
    padding-top: 0.7rem;
    padding-right: 1.5rem;
    flex-basis: 50%;
  }

  .weeklyCont {
    display: flex;
    background-color: #282744;
    padding: 0.6rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 1152px) {
  .analyticsCont {
    display: flex;
    /* grid-template-columns: 2fr 3.5fr 2fr; */
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    margin-top: 5rem;
  }
  .donutandSpider {
    display: flex;
    height: 30%;
    flex-basis: 100%;
    gap: 2rem;
  }
  .donutCont {
    flex-basis: 50%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
    /* background-color: orangered; */
  }

  .spiderCont {
    /* background-color: yellow; */
    display: grid;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
  }

  .circleWidth {
    width: 10rem;
  }

  .circleandGraph {
    /* background-color: red; */
    display: grid;
    grid-template-rows: 1fr 1fr;
    /* padding-left: 16%; */
    flex-basis: 100%;
    height: fit-content;
    margin-top: 5rem;
  }

  .circleCont {
    /* background-color: white; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .lineChartCont {
    /* background-color: red; */
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 85%;
    justify-content: center;
    margin: 0px;
    justify-self: center;
  }
}

@media (max-width: 2016px) {
  .circleCont {
    /* background-color: white; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-left: 2rem;
  }
}

@media (max-width: 860px) {
  .analyticsCont {
    display: grid;
    gap: 5rem;
    grid-template-columns: 1fr;
    /* grid-template-rows: 1fr 2fr 3fr; */
  }

  .dlWidth {
    justify-self: start;
  }

  .donutandSpider {
    /* background-color: cyan; */
    justify-content: center;
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 7rem;
  }

  .donutCont {
    /* margin-top: 4rem; */
    width: 110%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
    /* background-color: orangered; */
  }

  .circleandGraph {
    /* background-color: red; */
    display: grid;
    justify-content: center;
    height: fit-content;
    gap: 3rem;
    grid-template-rows: 1fr 1fr;
    /* padding-left: 16%; */
    padding: 1rem;
    margin-top: 0;
  }

  .circleCont {
    /* background-color: white; */
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5rem;

    margin-left: 3rem;
  }

  .circleDiv {
    /* background-color: cyan; */
    justify-self: center;
    margin-left: 0rem;
    padding: 0;
    /* padding-left: 6rem; */
  }

  .circleWidth {
    width: 10rem;
  }

  .radialCont {
    /* background-color: #9b9b9b; */
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    align-self: center;
    justify-self: center;
    /* padding: 0.2rem; */
  }

  .radialandCalender {
    /* background-color: violet; */
    height: fit-content;
    display: grid;
    grid-template-rows: 1fr 3fr;
    gap: 5rem;
    margin-top: 0;
  }

  .calendarCont {
    /* background-color: purple; */
    width: 93%;
    align-items: center;
    align-self: start;
    justify-self: center;
    /* padding: 0.5rem; */
    /* padding-top: 0.7rem; */
    /* padding-right: 1.2rem; */
  }

  .weeklyCont {
    display: flex;
    background-color: #282744;
    padding: 0.6rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    gap: 2.7rem;
  }
}

@media (max-width: 693px) {
  .circleCont {
    /* background-color: white; */
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5rem;

    margin-left: 1.5rem;
  }
}

@media (max-width: 639px) {
  .circleCont {
    /* background-color: white; */
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5rem;

    margin-left: 0.8rem;
  }
}

@media (max-width: 615px) {
  .circleWidth {
    width: 8rem;
  }
}

@media (max-width: 554px) {
  .circleWidth {
    width: 7rem;
  }
}

@media (max-width: 525px) {
  .circleWidth {
    width: 6.5rem;
  }
}
