.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 315px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 9999;
  animation: fadeOut 1s ease-in-out forwards;
}

.loginCont {
  background-color: rgb(82, 82, 82);
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.popup h3 {
  text-align: center;
  margin-bottom: 2rem;
}

/* Styles for the login form inside the popup */
.popup form {
  display: flex;
  flex-direction: column;
  z-index: 9999;
}

.popup label,
.popup input[type="text"],
.popup input[type="password"] {
  margin-bottom: 3px;
  color: black;
  z-index: 9999;
  height: 2rem;
  font-size: 14px;
  padding-left: 5px;
}

.popup input[type="text"]::placeholder,
.popup input[type="password"]::placeholder {
  font-size: 14px;
}

.popup input[type="submit"] {
  padding: 10px;
  background-color: rgba(8, 177, 130, 0.7);
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.popup input[type="submit"]:hover {
  background-color: rgba(8, 177, 130, 2);
}

.errMsg {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.errMsg span {
  color: orange;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 314px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    width: 230px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 9999;
    animation: fadeOut 1s ease-in-out forwards;
  }
}

@media only screen and (max-width: 238px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    width: 180px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 9999;
    animation: fadeOut 1s ease-in-out forwards;
  }
}

@media only screen and (max-width: 185px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    width: 150px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 9999;
    animation: fadeOut 1s ease-in-out forwards;
  }
}

@media only screen and (max-width: 150px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    width: 130px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 9999;
    animation: fadeOut 1s ease-in-out forwards;
  }
}
